<template>
  <vs-card>
    <div slot="header">
      <h3>
        {{ title }}
      </h3>
    </div>
    <quillEditor :pages="pages"></quillEditor><br />
    <div>
      <!-- <vs-row vs-justify="flex-end">
              <vs-button color="success" @click="savePageDetails">Save</vs-button>
              <vs-button color="danger" style="margin-left:5px;" @click="$router.replace('/admin/cms-pages')">Cancel</vs-button>
        </vs-row> -->

      <vs-row class="flex flex-wrap items-center p-6">
        <vs-button
          class="mr-6"
          color="danger"
          type="flat"
          @click="$router.replace('/admin/cms-pages')"
          >Cancel</vs-button
        >
        <vs-button color="primary" @click="savePageDetails">Save</vs-button>
      </vs-row>
    </div>
  </vs-card>
</template>

<script>
import { mapActions } from "vuex";
import quillEditor from "../Editor";
import moment from "moment";
import { pageMixin } from '../../mixins/pageMixin'

export default {
  name: "pageViewEditDetail",
  components: {
    quillEditor,
  },
  mixins: [pageMixin],
  data: () => ({
    pages: {
      content: "",
    },
    title: "",
  }),
  methods: {
    ...mapActions("page", ["fetchPageDetails", "updatePageDetails"]),
    getPageDetail(id) {
      let self = this;
      this.getPageDetails(id).then((res) => {
        self.pages.content = res.page.content;
        self.title = res.page.title;
      });
    },
    savePageDetails() {
      let _id = this.$route.params.id;
      let content = this.pages.content;
      this.updatePage({ _id, content }).then((res) => {
        this.$vs.notify({
          title: "Update Page Content",
          text: res.message.message,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "success",
        });
      });

    },
  },
 created() {
    let pageId = this.$route.params.id;
    this.getPageDetail(pageId);
  },
};
</script>
<style>
.ql-editor {
  min-height: 200px;
}
</style>